import React from "react"
import { Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import FooterSection from "../FooterSection/index"
import { GatsbyImage } from "gatsby-plugin-image"
import CTARightArrow from "@images/img-min/ctaRightArrow.svg"

import Seismic from "@images/img-min/mitigation-overview-seismic.svg"
import Drought from "@images/img-min/mitigation-overview-drought.svg"
import Flood from "@images/img-min/mitigation-overview-flood.svg"
import Wildfire from "@images/img-min/mitigation-overview-wildfire.svg"
import Precipitation from "@images/img-min/mitigation-overview-precipitation.svg"
import ExtremeWind from "@images/img-min/mitigation-overview-extreme-wind.svg"
import ExtremeHeat from "@images/img-min/mitigation-overview-extreme-heat.svg"
import Hurricane from "@images/img-min/mitigation-overview-hurricane.svg"

const MitigationOverview = ({ backgroundImage, mitigationImg }) => {
  const links = [
    { label: "Flood", to: "/risks/flood" },
    { label: "Fire", to: "/risks/fire" },
    { label: "Heat", to: "/risks/heat" },
    { label: "Drought", to: "/risks/drought" },
    { label: "Storm", to: "/risks/storm" },
  ]

  const image = convertToBgImage(getImage(backgroundImage))

  return (
    <BackgroundImage {...image} className="mitigation-overview-background">
      <section id="mitigation-overview" className="mitigation-overview">
        <div className="container">
          <div className="mitigation-overview--left">
            <h2>Protect your real estate assets</h2>
            <p>
              Many climate change risks can be mitigated through adaptations
              that make your property more resilient to extreme weather hazards.
            </p>
            <Link to="/risks/mitigation" className="link-btn">
              Mitigate Your Risks
              <CTARightArrow />
            </Link>
          </div>
          <div className="mitigation-overview--right">
            <div className="donut">
              <h3>Seismic</h3>
              <Seismic />
            </div>
            <div className="donut">
              <h3>Drought</h3>
              <Drought />
            </div>
            <div className="donut">
              <h3>Flood</h3>
              <Flood />
            </div>
            <div className="donut">
              <h3>Wildfire</h3>
              <Wildfire />
            </div>
            <div className="donut">
              <h3>Precipitation</h3>
              <Precipitation />
            </div>
            <div className="donut">
              <h3>Extreme Wind</h3>
              <ExtremeWind />
            </div>
            <div className="donut">
              <h3>Extreme Heat</h3>
              <ExtremeHeat />
            </div>
            <div className="donut">
              <h3>Hurricane</h3>
              <Hurricane />
            </div>
          </div>
        </div>
      </section>
    </BackgroundImage>
  )
}

export default MitigationOverview
